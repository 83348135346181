import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default () => (
  <StaticQuery
    query={graphql`
    query HeadingQuery {
      contentfulPageContent(slug: {eq: "/"}) {
        title
        bodyContent {
          json
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
        <div className="headline">{data.contentfulPageContent.title}</div>
        <div className="primary-content"> 
          {documentToReactComponents(data.contentfulPageContent.bodyContent.json)}
        </div>
        </div>
    )}
  />
)