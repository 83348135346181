import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import PodcastLink from "../components/podcast-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({
  data: {
    allContentfulBlogPost,
    allContentfulPodcast
  },
}) => {

  const Posts = allContentfulBlogPost.edges
    .filter(edge => !!edge.node.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  const Podcasts = allContentfulPodcast.edges
    .filter(edge => !!edge.node.date) // You can filter your posts based on some criteria
    .map(edge => <PodcastLink key={edge.node.id} podcast={edge.node} />)  

  return (
    <Layout>
      <HeroHeader/>
      <h5>Blog Posts &darr;</h5>
      <div className="grids">
        {Posts}
      </div>
      <h5>Podcast Interviews &darr;</h5>
      <div className="grids">
        {Podcasts}
      </div>


    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    allContentfulBlogPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          id
          slug
          abstract
          date (formatString: "Do MMMM, YYYY")
          featuredImage {
            fluid {
              src
            }
          }
        }
      }
    }
    allContentfulPodcast(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          id
          slug
          url
          player
          date (formatString: "Do MMMM, YYYY")
          featuredImage {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
