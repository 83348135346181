import React from "react"
import Iframe from 'react-iframe';

const PodcastLink = ({ podcast }) => (
  <article className="card">
    <header>
      <div className="post-meta">{podcast.date}</div>
      <h2 className="post-title">
        {podcast.title}
      </h2>
      <div>
        <Iframe className="player" title="podcast-player" src={podcast.player} frameBorder="0"/>
      </div>
    </header>
  </article>
)
export default PodcastLink
